import React from 'react';
import { Constants } from '../../constants/constants';
import profilePic from '../../assets/images/me.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCalendarAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';


const Profile = () => {
    return (
        <div className="profile-section mt-5" id="profile">
            <div className="container profile-card-container d-flex">
                <div className="row  pt-3 pb-3 bg-light shadow-lg rounded d-flex align-items-center">
                    <div className="col-xl-8 profile-pic-container">
                        <img src={profilePic} alt="Me" className="rounded profile-pic img-fluid" />
                    </div>
                    <div className="col-xl-4 profile-intro-col">
                        <div className="row profile-intro-row">
                            <div className="col">
                                <h6 className="text-uppercase mb-3">{Constants.messages.hiThere} {Constants.messages.iAm}</h6>
                                <h3 className="font-weight-bold text-uppercase mb-4">{Constants.profileInfo.name} </h3>
                                <h4 className="mb-4">{Constants.profileInfo.designation}</h4>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col">
                                <span className="text-muted">{Constants.messages.intro}</span>
                            </div>
                        </div>
                        <ul className="no-list-style">
                            <li>
                            <FontAwesomeIcon icon={faEnvelope} size={Constants.constant.sizeLg} />
                            {Constants.profileInfo.email}
                            </li>
                            <li>
                            <FontAwesomeIcon icon={faCalendarAlt} size={Constants.constant.sizeLg} />
                            {Constants.profileInfo.dob}
                            </li>
                            <li>
                            <FontAwesomeIcon icon={faMapMarkerAlt} size={Constants.constant.sizeLg} />
                            {Constants.profileInfo.address}
                            </li>
                        </ul>

                        <div className="row d-flex  mt-5 pl-1">
                            <div className="col-2 col-sm-2">
                                <button type="button" className="btn btn-light social-icons profile-scroll-reveal-icons" onClick={() => window.open(Constants.profileInfo.socialLinks.twitter, '_blank')}>
                                    <FontAwesomeIcon icon={faTwitter} color={Constants.constant.whiteColor} size={Constants.constant.sizeLg} />
                                </button>
                            </div>
                            <div className="col-2 col-sm-2">
                                <button type="button" className="btn btn-light social-icons profile-scroll-reveal-icons" onClick={() => window.open(Constants.profileInfo.socialLinks.linkedin, '_blank')}>
                                    <FontAwesomeIcon icon={faLinkedin} color={Constants.constant.whiteColor} size={Constants.constant.sizeLg} />
                                </button>
                            </div>
                            <div className="col-2 col-sm-2">
                                <button type="button" className="btn btn-light social-icons profile-scroll-reveal-icons" onClick={() => window.open(Constants.profileInfo.socialLinks.github, '_blank')}>
                                    <FontAwesomeIcon icon={faGithub} color={Constants.constant.whiteColor} size={Constants.constant.sizeLg} />
                                </button>
                            </div>
                            <div className="col-2 col-sm-2">
                                <button type="button" className="btn btn-light social-icons profile-scroll-reveal-icons" onClick={() => window.open(Constants.profileInfo.socialLinks.medium, '_blank')}>
                                    <FontAwesomeIcon icon={faMedium} color={Constants.constant.whiteColor} size={Constants.constant.sizeLg} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Profile;

export const Constants = Object.freeze({

    appInfo:{
        title:'Vipin | Portfolio',
        sections:{
            home:'Home',
            about:'About',
            timeline:'Timeline',
            skills:'Skills',
        }
    },

    constant: {
        name: 'Vipin sharma',
        email: 'vicky.sharma8127344@gmail.com',
        dob: '23-Sep-1995',
        address: 'Delhi, India',
        designation: 'Software Engineer',
        whiteColor: 'white',
        sizeLg: 'lg'
    },

    messages: {
        hiThere: 'Hi there',
        iAm: 'I am,',
        intro: 'Creative Software Engineer having a experience of building web apps, mobile apps, and interactive features that drive business growth ',
        footerContent:'Copyright ©2020 All rights reserved',
        aboutMePart1:'<span>I graduated in 2017 with a bachelor’s degree in Information Technology from Krishna engineering college. Currently I am working as Module Lead Software Engineer in <a href="http://www.impledge.com/" target="_blank">Impledge Technologies</a>. I have been in the field for nearly 5 years, and have been loving every minute of it.</span>',
        aboutMePart2:'I love to explore new technologies and interact with people to get know about their thought process about technology revolution. During my free time, I love to play cricket.'
    },

    profileInfo:{
        name: 'Vipin sharma',
        email: 'vicky.sharma8127344@gmail.com',
        dob: '23-Sep-1995',
        address: 'Delhi, India',
        designation: 'Software Engineer',
        socialLinks:{
            twitter:'https://twitter.com/vipinsharma82',
            linkedin:'https://www.linkedin.com/in/vipin-sharma-224313136',
            github:'https://github.com/vipin55555',
            medium:'https://medium.com/@vicky.sharma8127344',
        }
    },

    experience: [
        {
            title: 'Impledge Technologies',
            subtitle: 'Software Engineer',
            place: 'Noida, India',
            duration: 'Sep 2018 - Present',
        },
        {
            title: 'Innotical Solutions',
            subtitle: 'Software Engineer',
            place: 'Noida, India',
            duration: 'June 2018 - Aug 2018',
        },
        {
            title: 'Innotical Solutions',
            subtitle: 'Junior Software Engineer',
            place: 'Noida, India',
            duration: 'Dec 2017 - May 2018',
        },
        {
            title: 'Innotical Solutions',
            subtitle: 'Software Intern',
            place: 'Noida, India',
            duration: 'June 2017 - Nov 2017',
        }
    ],

    education: [
        {
            title: 'Krishna Engineering College',
            subtitle: '',
            place: 'Ghaziabad, India',
            duration: 'Aug 2013 - May 2017',
        },
        {
            title: 'Arwachin Bharti Bhawan Sr. Sec. School',
            subtitle: '',
            place: 'Delhi, India',
            duration: 'July 2011 - July 2013',
        },
        {
            title: 'Shashi Public Sec. School',
            subtitle: '',
            place: 'Delhi, India',
            duration: 'July 2001 - May 2011',
        }
    ],

});

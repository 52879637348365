import React from 'react';
import { Constants } from '../../constants/constants';
import Particles from 'react-particles-js';

export default class TimeLine extends React.Component {
    particleObj;

    componentDidMount() {
        this.particleObj = {
            particles: {
                number: {
                    value: Math.floor((typeof window !== `undefined` ? window.innerWidth : 1000) / 10),
                    density: {
                        enable: true,
                        value_area: 800
                    }
                }
            }
        }
    }

    state = {
        timelineData: Constants.experience,
        isExperienceButtonActive: true
    };

    renderData(data, isExperienceButtonActive) {
        this.setState({
            timelineData: data,
            isExperienceButtonActive: isExperienceButtonActive
        });
    }

    render() {
        return (
            <div id="timeline-section">
                <Particles params={this.particleObj} className="particles" style={{ position: 'absolute' }} />

                <div className="row d-flex justify-content-center mb-4">
                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                        <button type="button" className={`btn btn-outline-secondary padding-top-bottom-10px-left-right-18px ${this.state.isExperienceButtonActive ? 'active' : ''}`} onClick={() => this.renderData(Constants.experience, true)}>My Experience</button>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                        <button type="button" className={`btn btn-outline-secondary padding-top-bottom-10px-left-right-18px ${!this.state.isExperienceButtonActive ? 'active' : ''}`} onClick={() => this.renderData(Constants.education, false)}>My Education </button>
                    </div>
                </div>

                <div className="timeline-container">
                    <ul>
                        {this.state.timelineData.map((data, index) => (
                            <li key={index}>
                                <div className="content">
                                    <h3>
                                        <span >{data.title}</span>
                                    </h3>
                                    <p>
                                        <span>{data.subtitle}</span>
                                        {data.subtitle !== '' ? <br /> : ''}
                                        <span>{data.place}</span>
                                    </p>
                                </div>
                                <div className="time">
                                    <h4> {data.duration}</h4>
                                </div>
                            </li>
                        ))}
                        <div style={{ clear: 'both' }}></div>
                    </ul>
                </div>
            </div>
        )
    }
}

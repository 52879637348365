import React from 'react';
import angularIcon from '../../assets/images/skill-icons/angular.png';
import reactIcon from '../../assets/images/skill-icons/react.png';
import gatsbyIcon from '../../assets/images/skill-icons/gatsby.png';
import ionicIcon from '../../assets/images/skill-icons/ionic.png';
import dotnetCoreIcon from '../../assets/images/skill-icons/dotnetcore.png';
import nodeIcon from '../../assets/images/skill-icons/node.png';
import nestJSIcon from '../../assets/images/skill-icons/nestjs.png';
import mongoDBIcon from '../../assets/images/skill-icons/mongodb.png';

const Skill = () => {
    return (
        <div className="skill-section" id="skill">
            <div className="container mt-4 mb-4">
                <div className="row mt-2 mb-2">
                    <div className="col-12">
                        <h2>Skills</h2>
                    </div>
                </div>
                <div className="card-deck">
                    <div className="card" id="skill-card-1">
                        <div className="card-body">
                            <div className="row text-center">
                                <div className="col col-md-6 col-lg-3 mt-2 mb-2">
                                    <img alt="" src={angularIcon} className="responsive-img" />
                                    <span>Angular</span>
                                </div>
                                <div className="col col-md-6 col-lg-3 mt-2 mb-2">
                                    <img alt="" src={reactIcon} className="responsive-img" />
                                    <span>React</span>
                                </div>
                                <div className="col col-md-6 col-lg-3 mt-2 mb-2">
                                    <img alt="" src={gatsbyIcon} className="responsive-img" />
                                    <span>Gatsby</span>
                                </div>
                                <div className="col col-md-6 col-lg-3 mt-2 mb-2">
                                    <img alt="" src={ionicIcon} className="responsive-img" />
                                    <span>Ionic</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card" id="skill-card-2">
                        <div id="diamond"></div>
                        <div className="card-body">
                            <div className="row text-center">
                                <div className="col col-md-6 col-lg-3 mt-2 mb-2">
                                    <img alt="" src={dotnetCoreIcon} className="responsive-img" />
                                    <span>.Net Core</span>
                                </div>
                                <div className="col col-md-6 col-lg-3 mt-2 mb-2">
                                    <img alt="" src={nodeIcon} className="responsive-img" />
                                    <span>Node</span>
                                </div>
                                <div className="col col-md-6 col-lg-3 mt-2 mb-2">
                                    <img alt="" src={nestJSIcon} className="responsive-img" />
                                    <span>NestJS</span>
                                </div>
                                <div className="col col-md-6 col-lg-3 mt-2 mb-2">
                                    <img alt="" src={mongoDBIcon} className="responsive-img" />
                                    <span>MongoDB</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skill;

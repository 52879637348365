import React from 'react';
import { Constants } from '../../constants/constants';

const About = () => {

    function aboutMePart1() {
        return { __html: Constants.messages.aboutMePart1 };
    }

    return (
        <div className="about-section" id="about">
            <div className="container d-flex">
                <div className="row">
                    <div className="col-12">
                        <p>About Me</p>
                    </div>
                    <div className="col-12">
                        <h2>Who am I ?</h2>
                    </div>
                    <div className="col-12">
                        <p dangerouslySetInnerHTML={aboutMePart1()}></p>
                    </div>
                    <div className="col-12">
                        <p>{Constants.messages.aboutMePart2}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
import React from 'react';
import { Constants } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <div className="footer-section" >
            <hr />
            <div className="container mt-4 mb-4">
                <footer className="page-footer font-small">
                    <div className="row d-flex align-items-center">

                        <div className="col-md-7 col-lg-8">

                            <p className="text-center text-md-left">
                                {Constants.messages.footerContent}
                            </p>

                        </div>

                        <div className="col-md-5 col-lg-4 ml-lg-0">
                            <div className="text-center text-md-right">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item">
                                        <button type="button" className="btn btn-light social-icons" onClick={() => window.open(Constants.profileInfo.socialLinks.twitter, '_blank')}>
                                            <FontAwesomeIcon icon={faTwitter} color={Constants.constant.whiteColor} size={Constants.constant.sizeLg} />
                                        </button>
                                    </li>
                                    <li className="list-inline-item">
                                        <button type="button" className="btn btn-light social-icons" onClick={() => window.open(Constants.profileInfo.socialLinks.linkedin, '_blank')}>
                                            <FontAwesomeIcon icon={faLinkedin} color={Constants.constant.whiteColor} size={Constants.constant.sizeLg} />
                                        </button>
                                    </li>
                                    <li className="list-inline-item">
                                        <button type="button" className="btn btn-light social-icons" onClick={() => window.open(Constants.profileInfo.socialLinks.github, '_blank')}>
                                            <FontAwesomeIcon icon={faGithub} color={Constants.constant.whiteColor} size={Constants.constant.sizeLg} />
                                        </button>
                                    </li>
                                    <li className="list-inline-item">
                                        <button type="button" className="btn btn-light social-icons" onClick={() => window.open(Constants.profileInfo.socialLinks.medium, '_blank')}>
                                            <FontAwesomeIcon icon={faMedium} color={Constants.constant.whiteColor} size={Constants.constant.sizeLg} />
                                        </button>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>

                </footer>
            </div>
        </div>
    );
}

export default Footer;
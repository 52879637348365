import React, { useEffect } from 'react';
import Profile from '../profile/profile';
import About from '../about/about';
import TimeLine from '../timeline/timeline';
import Skill from '../skills/skill';
import Footer from '../footer/footer';
import codeLogo from '../../assets/images/code.jpg';
import { Constants } from '../../constants/constants';

const Layout = () => {
    useEffect(() => {
        try {
            var ScrollRevealModule = require("scrollreveal");
            var ScrollReveal = ScrollRevealModule.default;
            if (typeof window !== `undefined`) {
                ScrollReveal().reveal('.profile-pic-container', {
                    duration: 2000,
                    orgin: 'top'
                });
                ScrollReveal().reveal('.profile-scroll-reveal-icons', {
                    duration: 2000,
                    delay: 500,
                    orgin: 'top',
                });
                ScrollReveal().reveal('#about', {
                    duration: 2000,
                    orgin: 'bottom',
                    viewFactor: 0.2
                });
                ScrollReveal().reveal('#skill-card-1', {
                    duration: 2000,
                    orgin: 'left',
                    viewFactor: 0.2
                });
                ScrollReveal().reveal('#skill-card-2', {
                    duration: 2000,
                    orgin: 'right',
                    viewFactor: 0.2
                });
            }
        } catch (e) {
            throw e;
        }
    });

    return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="#profile">
                    <img src={codeLogo} width="30" height="30" alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#profile">{Constants.appInfo.sections.home}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#about">{Constants.appInfo.sections.about}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#timeline-section">{Constants.appInfo.sections.timeline}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#skill">{Constants.appInfo.sections.skills}</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <Profile />
            <About />
            <TimeLine />
            <Skill />
            <Footer />
        </div>
    )
};

export default Layout;